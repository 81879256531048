import React, { Suspense } from 'react';
import { DynamicComponent, EmbeddedVideo, StoryInfo, VideoArticlePropTypes } from '@tjclifton/storyblok-react-utils';
import { Button, Container, Header, Icon, Loader, Segment, SegmentGroup } from 'semantic-ui-react';
import classNames from 'classnames';

import styles from '@styles/components/storyblok/Video.module.sass';

/**
 *
 * @param props
 * @returns
 */
export const Video: React.FC<VideoArticlePropTypes> = props => {
  return <Container text>
    <SegmentGroup className="shadowed">
      <Segment attached="bottom" className={classNames('fitted', 'video', 'wrapper')}>
        <Suspense fallback={<Loader />}>
          <EmbeddedVideo provider="youtube" url={props.blok.source.cached_url} />
        </Suspense>
      </Segment>

      <Segment attached padded className={styles.video}>
        <Header as={`h${props.headerSize || 1}`} textAlign="center" className={styles.title}>
          {props.blok.title}
          <Header sub>{props.blok.subtitle}</Header>

          <Header as={'h6'}><StoryInfo /></Header>
        </Header>

        {props.blok.description.map(blok =>
          <DynamicComponent blok={blok} key={blok._uid} />
        )}
      </Segment>
    </SegmentGroup>
  </Container>;
};

