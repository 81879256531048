import React, { Suspense, useContext } from 'react';

import styles from '@styles/App.module.sass';

import Burden from './components/storyblok/Burden';
import { Video } from './components/storyblok/Video';

import { ComponentLookupContext, StoryRouter, DynamicComponent, MobileLayout, NavigationItem, SidebarContext, useLocalizedImage, useResponsiveValue, useStoryblokNavigation, useStoryblokPaths, Visibility, useStoryblokNavigationTree, TreeNavigationItem, isPlaceholder, hasChildren } from '@tjclifton/storyblok-react-utils';
import { Button, Container, Grid, GridColumn, Icon, Menu } from 'semantic-ui-react';
import { StoryData } from 'storyblok-js-client';

import { Link, NavLink } from 'react-router-dom';
import { useCallback } from 'react';
import classNames from 'classnames';
import { Page } from './components/storyblok/Page';

/**
 *
 */
declare const window: {
  FB?: {
    CustomerChat?: {
      hide(): void;
    }
  }
};

/**
 *
 */
interface ContentProps {
  readonly navItems: ReadonlyArray<NavigationItem | TreeNavigationItem>;
}

/**
 *
 * @param props
 */
const Content: React.FC<ContentProps> = props => {
  const { setVisible: setSidebarVisible } = useContext(SidebarContext);
  const { pathFor, nameFor } = useStoryblokPaths();
  const logoUrl = useLocalizedImage('logo.png');

  const lessPadded = useResponsiveValue({max: 'md'}, true, false);

  const onContentChange = useCallback((content?: StoryData<unknown>) => {
    if (content?.full_slug !== 'contact') window.FB?.CustomerChat?.hide();
  }, []);

  /**
   *
   */
  const renderNavMenu = useCallback((navItems: typeof props['navItems']) =>
    <Menu secondary pointing inverted>
      {navItems.map(item =>
        <Menu.Item
          as={isPlaceholder(item) ? 'div' : NavLink}
          className={classNames({'ui simple dropdown': hasChildren(item)})}
          key={item.id}
          {
            ...isPlaceholder(item)
              ? {}
              : {
                activeClassName: 'active',
                exact: true,
                to: pathFor(item),
              }
          }
        >
          {nameFor(item)}
          {hasChildren(item) &&
            <>
              <Icon name="dropdown" />
              {renderNavMenu(item.children)}
            </>
          }
        </Menu.Item>
      )}
    </Menu>
  , []);

  return <StoryRouter
    storyType="page"
    wrapperClasses={[styles.wrapper]}
    notFoundSlug="not-found"
    onStoryChange={onContentChange}
    loaderColor="yellow"
  >
    {data =>
      <div className={classNames(
        styles.container,
        {[styles.lessPaddedContainer]: lessPadded},
      )}>
        <header className={classNames(
          styles.header,
          {[styles.lessPaddedHeader]: lessPadded}
        )}>
          <Visibility below="md">
            <Button basic className={styles.menuButton} inverted icon="bars" onClick={() => setSidebarVisible(true)} />
          </Visibility>

          {logoUrl &&
            <Link to="/">
              <Visibility below="md">
                <img src={logoUrl} title="Folly in Israel" width="80%" />
              </Visibility>
              <Visibility above="md">
                <img src={logoUrl} title="Folly in Israel" width="500px" />
              </Visibility>
            </Link>
          }
        </header>

        <Container>
          <Visibility min="md">
            <nav className={styles.nav}>
              {renderNavMenu(props.navItems)}
            </nav>
          </Visibility>
          <section>
            <Suspense fallback={null}>
              <DynamicComponent blok={data.content} />
            </Suspense>
          </section>
        </Container>
        <footer className={styles.footer}>
          <Grid stackable columns={3}>
            <GridColumn></GridColumn>
            <GridColumn textAlign="center">
              Copyright &copy; 2021.
            </GridColumn>
            <GridColumn></GridColumn>
          </Grid>
        </footer>
      </div>
    }
  </StoryRouter>;
};

/**
 *
 * @param props
 * @returns
 */
export const App: React.FC = props => {
  const { links } = useStoryblokNavigationTree();

  return <MobileLayout navItems={links || []}>
    <ComponentLookupContext.Provider value={{
      Burden,
      Video,
      Page
    }}>
      <Content navItems={links || []} />
    </ComponentLookupContext.Provider>
  </MobileLayout>;
};
